<template>
    <div>
        <ValidationObserver ref="studentForm">
            <div class="row">
                <div class="col-12">
                    <ValidationProvider name="studentNumber" rules="required" v-slot="{valid, errors}">
                        <b-form-group :label="$t('student_number')">
                            <b-form-input
                                v-model="studentForm.studentNumber"
                                :state="errors[0] ? false : (valid ? true : null)">
                                >
                            </b-form-input>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                        </b-form-group>
                    </ValidationProvider>
                </div>
                <div class="col-12">
                    <ValidationProvider name="birthdate" rules="required" v-slot="{valid, errors}">
                        <b-form-group :label="$t('date_of_birth')"
                                      class="position-relative">
                            <v-date-picker
                                v-model="studentForm.birthdate"
                                :locale="$i18n.locale"
                                is-expanded
                                :masks="masks"
                                :max-date="new Date()"
                                :popover="{ 'visibility': 'click' }">

                                <template
                                    v-slot="{ inputValue, inputEvents }">
                                    <b-input-group>
                                        <b-form-input
                                            :readonly="true"
                                            :value="inputValue"
                                            v-on="inputEvents"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                        ></b-form-input>
                                        <b-input-group-append>
                                            <b-button
                                                variant="outline-secondary"
                                                class="btn-40"
                                                disabled
                                            >
                                                <i class="ri-calendar-line"></i
                                                ></b-button>
                                        </b-input-group-append>
                                        <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>

                                    </b-input-group>
                                </template>
                            </v-date-picker>
                        </b-form-group>
                    </ValidationProvider>
                </div>
                <div class="col-12">
                    <b-button
                        @click="submitStudentForm"
                        type="button"
                        variant="primary"
                        class="btn-lg btn-block d-flex justify-content-between align-items-center"
                    >
                        {{ $t('send_password').toUpper() }}
                    </b-button>
                </div>
            </div>
        </ValidationObserver>
    </div>
</template>
<script>
    import {ValidationObserver, ValidationProvider} from "vee-validate";
    import AuthService from "@/services/AuthService";
    import moment from "moment";

    export default {
        components: {
            ValidationProvider,
            ValidationObserver
        },
        data() {
            return {
                studentForm: {
                    studentNumber: '',
                    birthdate: '',
                },

                masks: {
                    input: "DD-MM-YYYY",
                },
            };
        },
        methods: {
            onCaptchaVerified: function (recaptchaToken) {
                this.studentForm.birthdate = moment(this.studentForm.birthdate).format('YYYY-MM-DD');
                const studentForm = {
                    type: 'student',
                    student_number: this.studentForm.studentNumber,
                    birthdate: this.studentForm.birthdate,
                    g_recaptcha_token: recaptchaToken
                }
                AuthService.passwordReset(studentForm)
                    .then(response => {
                        this.$toast.success(this.$t('api.' + response.data.message))
                        this.$store.commit('auth/setActivePage', 'login');
                    })
                    .catch(error => {
                        if (error.status == 422) {
                            if (error.data.errors.student_number) {
                                this.$refs.studentForm.errors.studentNumber.push(error.data.errors.student_number[0]);
                            }
                            if (error.data.errors.birthdate) {
                                this.$refs.studentForm.errors.birthdate.push(error.data.errors.birthdate[0]);
                            }
                        }
                        else if (error.status == 406) {
                            this.$toast.error(this.$t('api.' + error.data.message));
                        }
                    });
            },
            async submitStudentForm() {
                const isValid = await this.$refs.studentForm.validate();
                if (isValid) {
                    this.$emit('captcha', 'resetPasswordStudent');
                }
            },
        },
    };
</script>
