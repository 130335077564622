<template>
    <div>
        <auth-layout>
            <slot></slot>
            <vue-recaptcha ref="recaptcha" @verify="onCaptchaVerified" size="invisible"
                           sitekey="6LelIscaAAAAAA0ODBaTFs_FUqS3WTgy-G0bP1pG"/>
            <div class="row vh-100 overflow-x-hidden">
                <div class="col-12 col-lg-6 bg-white rounded-lg mr-0 mr-md-minus-5px z-index-1 shadow">
                    <div v-if="announcements.length">
                    <b-card class="text-center" style="height: 100px" v-if="announcements.length>1">
                        <transition name="slide-fade" mode="out-in">
                            <div
                                v-if="show"
                                :key="currentAnnouncement.id"
                                style="display: flex; justify-content: space-between; align-items: center; text-align: center">
                                    <span class="mr-3">
                                      <b-icon
                                          icon="bell-fill"
                                          class="rounded-circle bg-primary p-2"
                                          style="height: 40px; width: 40px"
                                          variant="light"
                                      ></b-icon>
                                    </span>
                                <span class="mt-2">
                                        <span class="mb-4">
                                           {{ currentAnnouncement.title }}
                                        </span>
                                        <a v-if="currentAnnouncement.link" :href="currentAnnouncement.link"
                                           target="_blank"
                                           class="text-decoration-none "
                                           style="color: inherit"><p
                                            style="font-family: cursive;" class="truncated" v-html="truncatedText">
                                        </p>

                                        </a>
                                        <span v-else v-html="truncatedText"></span>

                                    </span>
                                <div>
                                    <b-button v-b-tooltip.hover.top="'Tüm Duyurular'" variant="primary"
                                              @click="showAnnouncementModal"><i class="ri-list-check"></i>
                                    </b-button>
                                </div>
                            </div>
                        </transition>
                    </b-card>
                        <b-card class="text-center" style="height: 100px"  v-else>
                            <div
                                :key="currentAnnouncement.id"
                                style="display: flex; justify-content: space-between; align-items: center; text-align: center">
                                    <span class="mr-3">
                                      <b-icon
                                          icon="bell-fill"
                                          class="rounded-circle bg-primary p-2"
                                          style="height: 40px; width: 40px"
                                          variant="light"
                                      ></b-icon>
                                    </span>
                                <span class="mt-2">
                                        <span class="mb-4">
                                           {{ currentAnnouncement.title }}
                                        </span>
                                        <a v-if="currentAnnouncement.link" :href="currentAnnouncement.link"
                                           target="_blank"
                                           class="text-decoration-none "
                                           style="color: inherit"><p
                                            style="font-family: cursive;" class="truncated" v-html="truncatedText">
                                        </p>

                                        </a>
                                        <span v-else v-html="truncatedText"></span>
                                    </span>
                                <div>
                                    <b-button v-b-tooltip.hover.top="'Tüm Duyurular'" variant="primary"
                                              @click="showAnnouncementModal"><i class="ri-list-check"></i>
                                    </b-button>
                                </div>
                            </div>
                        </b-card>
                    </div>
                    <div class="d-flex align-items-start flex-column h-100">
                        <div class="my-auto w-100">
                            <div class="row justify-content-center">
                                <div class="col-10 col-sm-7 col-md-5 col-lg-8 col-xl-7 col-xxl-6 login-wrapper py-5">
                                    <div class="mx-auto" :class="[{ 'login-w-limit': activePage == 'login' }]">
                                        <div class="logo-wrapper text-center text-lg-left mb-2 mb-lg-5 pb-2">
                                            <!-- <img src="@/assets/img/bau-logo-umis.png" height="175"/> -->
                                            <img :src="$store.getters['dashboard/getImage']('logo.png')" height="175"/>
                                        </div>
                                        <h4 class="text-center mb-5 d-block d-lg-none">
                                            {{ $t('student_information_system') }}
                                        </h4>
                                        <div class="fadeIn" v-if="activePage == 'login'">
                                            <login-view ref="login" @captcha="onCaptcha"></login-view>
                                        </div>
                                        <div class="fadeIn" v-if="activePage == 'forgot-password'">
                                            <reset-password-with-mobile-number ref="resetPasswordSms"
                                                                               @captcha="onCaptcha"/>
                                            <a class="small text-uppercase text-center d-block text-muted cursor-pointer"
                                               @click="changeActivePage('login')">
                                                <i class="ri-arrow-left-line mr-2 h6 font-weight-normal top-plus-2"></i>
                                                {{ $t('back').toUpper() }}
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <language-switch/>
                    </div>
                </div>
                <div class="col-6 p-0 bg-cover-center ml-minus-5px d-none d-lg-block login-bg-image login-right-img"
                     v-bind:style="{ 'background-image': `url('${$store.getters['dashboard/getImage']('login-background.png')}')` }">
                    <!-- v-bind:style="{ 'background-image': 'url(' + require('@/assets/img/100.year.png') + ')' }" -->
                    <!-- <div class="login-text">
                        {{ $t('bahcesehir_university') }} <br> {{ $t('student_information_system') }}
                    </div> -->
                </div>
            </div>

            <CommonModal ref="announcementModal">
                <template v-slot:CommonModalTitle>
                    <div>{{ $t('announcement').toUpper() }}</div>
                </template>
                <template v-slot:CommonModalContent>
                    <div class="row">
                        <b-col cols="12" v-for="(announcement,i) in announcements" :key="i">
                            <b-card class="mb-2" @click="announcement.show = !announcement.show">
                                <div class="d-flex" style="justify-content: space-between;align-items: center">
                                    <b-card-title>
                                        {{ announcement.title }}
                                    </b-card-title>
                                    <b-card-title>
                                        <i :key="i"
                                           :class="announcement.show ? 'ri-arrow-drop-down-line':'ri-arrow-right-s-line'"
                                           :aria-controls="'collapse'+ '-'+ i"
                                           style="font-size: 25px"
                                           :aria-expanded="announcement.show ? 'true' : 'false'">
                                        </i>
                                    </b-card-title>
                                </div>
                                <!--&lt;!&ndash;                                <b-card-text class="ellipsis-2" v-html="announcement.text">&ndash;&gt;-->
                                <!--                                </b-card-text>-->

                                <b-collapse :id="'collapse' + '-'+ i" v-model="announcement.show" :key="i" class="mt-2">
                                    <b-card>
                                        <span v-html="announcement.text"></span>
                                    </b-card>
                                </b-collapse>
                            </b-card>
                        </b-col>
                    </div>
                </template>
            </CommonModal>

        </auth-layout>
    </div>
</template>

<script>
// Template
import AuthLayout from '@/layouts/AuthLayout'

// Component
import ResetPasswordStudent from './ResetPasswordStudent';
import ResetPasswordStaff from './ResetPasswordStaff';
import ResetPasswordAlumni from './ResetPasswordAlumni';
import ResetPasswordWithMobileNumber from './ResetPasswordWithMobileNumber';
import LanguageSwitch from './LanguageSwitch';
import LoginView from './Login';

// Other
import {ValidationObserver, ValidationProvider} from 'vee-validate';
import {VueRecaptcha} from 'vue-recaptcha';
import AuthService from "@/services/AuthService";

export default {
    components: {
        AuthLayout,
        ValidationProvider,
        ValidationObserver,
        ResetPasswordStudent,
        ResetPasswordStaff,
        ResetPasswordAlumni,
        ResetPasswordWithMobileNumber,
        LanguageSwitch,
        LoginView,
        VueRecaptcha
    },
    metaInfo() {
        return {
            title: this.$t('login')
        }
    },
    data() {
        return {
            visible: false,
            show: false,
            announcements: [],
            // Login
            loginPasswordShowStatus: false,
            loginForm: {
                email: '',
                password: ''
            },

            // PasswordReset
            passwordResetPageTab: 'gsm',
            personalInformationActiveTabButton: 0,
            child: 'login',
            currentIndex: 0
        };
    },
    computed: {
        truncatedText() {
            const maxLength = 100;
            let text = this.currentAnnouncement.text;
            if (text.length > maxLength) {
                return text.substring(0, maxLength) + '...';
            }
            return text;
        },
        activePage() {
            return this.$store.getters['auth/getActivePage'];
        },
        currentAnnouncement() {
            return this.announcements[this.currentIndex];
        },


    },

    watch: {
        timerEnabled(value) {
            if (value) {
                setTimeout(() => {
                    this.timerCount--;
                }, 1000);
            }
        },
        timerCount: {
            handler(value) {
                if (value > 0 && this.timerEnabled) {
                    setTimeout(() => {
                        this.timerCount--;
                    }, 1000);
                } else if (value > 0 && !this.timerEnabled) {
                    this.alertShow = false;
                } else {
                    this.alertVariant = 'danger';
                    this.alertShow = true;
                }
            },
            immediate: true
        },
        announcements(value) {
            if (value.length) {
                this.show = true
                this.startAnnouncements();
            }
        }
    },
    created() {
        this.getAnnouncement()
    },

    methods: {
        showAnnouncementModal() {
            this.$refs.announcementModal.$refs.commonModal.show()
        },
        startAnnouncements() {
            setInterval(() => {
                this.show = false;
                setTimeout(() => {
                    this.currentIndex = (this.currentIndex + 1) % this.announcements.length;
                    this.show = true;
                }, 1000);
            }, 10000);
        },
        async getAnnouncement() {
            AuthService.getAnnouncement()
                .then(response => {
                    response.data.data.announcements.forEach(itm => {
                        this.announcements.push(
                            {
                                id: itm.id,
                                link: itm.link,
                                title: this.getLocaleText(itm, 'subject'),
                                text: this.getLocaleText(itm, 'explanation'),
                                show: false,
                            }
                        )
                    })
                }).catch(err => {
                this.showErrors(err)
            })

        },

        onCaptcha(child) {
            this.$refs.recaptcha.execute();
            this.child = child;
        },
        onCaptchaVerified: function (recaptchaToken) {
            if (this.child == 'login') {
                this.$refs.login.onCaptchaVerified(recaptchaToken);
            } else if (this.child == 'resetPasswordStudent') {
                this.$refs.resetPasswordStudent.onCaptchaVerified(recaptchaToken);
            } else if (this.child == 'resetPasswordStaff') {
                this.$refs.resetPasswordStaff.onCaptchaVerified(recaptchaToken);
            } else if (this.child == 'resetPasswordAlumni') {
                this.$refs.resetPasswordAlumni.onCaptchaVerified(recaptchaToken);
            } else if (this.child == 'resetPasswordSms') {
                this.$refs.resetPasswordSms.onCaptchaVerified(recaptchaToken);
            }
            this.$refs.recaptcha.reset();
        },
        changeActivePage(page) {
            this.$store.commit('auth/setActivePage', page);
        },
        personalInformationButtonTabClass(no) {
            if (this.personalInformationActiveTabButton === no) {
                return ['btn btn-outline-primary py-0'];
            } else {
                return ['btn btn-outline-secondary py-0'];
            }
        }
    }
};
</script>
<style>
.slide-fade-enter-active {
    transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
    transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
    transform: translateX(20px);
    opacity: 0;
}

p.truncated {
    display: -webkit-box;
    -webkit-line-clamp: 2; /* Satır sayısı */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
</style>
